<template>
    <div class="m-box--top"
         :class="'m-box--top-'+type"
    >
        <template v-if="type === '1'">
            <div class="m-box--side"
                 v-for="n in 2"
                 :class="{open: isOpen}, 'm-box--side-'+n"
            >
                <div class="m-box--side-space">
                    <div @click="selectSide('top_'+type)"></div>
                    <div v-for="n in 5"></div>
                </div>
            </div>
        </template>
        <template v-if="type === '2'">
            <div class="m-box--side"
                 v-for="n in 4"
                 :class="{open: isOpen}, 'm-box--side-'+n"
            >
                <div class="m-box--side-space">
                    <div @click="selectSide('top_'+type)"></div>
                    <div v-for="n in 5"></div>
                </div>
            </div>
        </template>
        <template v-if="type === '3'">
            <div class="m-box--side"
                 :class="{open: isOpen}"
            >
                <div class="m-box--side-part m-box--side-part--left">

                </div>
                <div class="m-box--side-part m-box--side-part--right">

                </div>
                <div class="m-box--side-part m-box--side-part--front">

                </div>
                <div class="m-box--side-space">
                    <div @click="selectSide('top_'+type)"></div>
                    <div v-for="n in 5"></div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "Top",
    props: ['type', 'isOpen'],
    methods: {
        selectSide(type){
            this.$emit('mSelectSide', type)
        }
    }
}
</script>

<style lang="scss">
.m-box {
    $self: &;
    $length: var(--box-length);
    $width: var(--box-width);
    $height: var(--box-height);
    $deep: var(--box-deep);

    &--side{
        &-part{
            position: absolute;
            top: 0;
            left: 0;
            width: 40%;
            max-width: 80px;
            background-color: inherit;
            border: 1px solid rgb(255, 255, 255);
            background-image: inherit;
            background-size: cover;
            transform-style: preserve-3d;
            cursor: pointer;
            transition: 0.3s;
            border-radius: 0 40px 40px 0;
            transform-origin: 0 0;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transform: translateZ(-1px);
                background-image: url("../../../src/assets/images/inner.jpg");
                background-size: cover;
                border-radius: 0 40px 40px 0;
            }

            &--left{
                height: 100%;
                transform: rotateY(-90deg) translateX(-100%);
                border-radius: 40px 0 0 40px;

                &:before{
                    border-radius: 40px 0 0 40px;
                }
            }

            &--right{
                height: 100%;
                transform: translateX(calc(#{$width} - 2px)) rotateY(90deg);
            }

            &--front{
                height: $width;
                transform: rotateZ(90deg) translateY(calc(0px - #{$width})) rotateY(90deg);
                top: calc(#{$length} - 1px);
            }
        }

        &:not(.open){
            #{$self}--side-part{
                &--left{
                    transform: rotateY(-100deg) translateX(-100%);
                }

                &--right{
                    transform: translateX(calc(#{$width} - 2px)) rotateY(100deg);
                }

                &--front{
                    transform: rotateZ(90deg) translateY(calc(0px - #{$width})) rotateY(100deg);
                }
            }
        }
    }

    &--top{
        transform-style: preserve-3d;

        &-1{
            #{$self}--side {
                &-1{
                    height: $length;
                    transform: rotateX(90deg);
                    transform-origin: 0 0;
                    transition-delay: 0.2s;

                    &.open {
                        transition-delay: 0s;
                        transform:
                            translateZ(calc(0px - (#{$deep} / 2)))
                            translateY(calc(0px - (#{$deep} / 2)))
                            rotateX(155deg);
                    }
                }

                &-2{
                    transition-delay: 0s;
                    top: 1px;
                    height: calc(#{$length} - 20px);
                    transform: translateZ(#{$length}) rotateY(-180deg) rotateX(90deg);
                    transform-origin: 50% 0;

                    &.open {
                        transition-delay: 0.2s;
                        transform:
                            translateZ(#{$length})
                            rotateY(-180deg)
                            translateY(calc(0px - (#{$deep} / 2)))
                            rotateX(110deg);
                    }
                }
            }
        }

        &-2{
            #{$self}--side {
                &-1{
                    height: calc(#{$length} / 2);
                    transform: rotateX(90deg);
                    transform-origin: 0 0;

                    &.open {
                        transform:
                            translateZ(calc(0px - (#{$deep} / 2)))
                            translateY(calc(0px - (#{$deep} / 2)))
                            rotateX(135deg);
                    }
                }

                &-2{
                    height: calc(#{$length} / 2);
                    transform: translateZ(#{$length}) rotateY(-180deg) rotateX(90deg);
                    transform-origin: 50% 0;

                    &.open {
                        transform:
                            translateZ(#{$length})
                            rotateY(-180deg)
                            translateY(calc(0px - (#{$deep} / 2)))
                            rotateX(135deg);
                    }
                }

                &-3{
                    top: 1px;
                    width: $length;
                    height: calc(#{$width} / 3);
                    transform: rotateY(90deg) translateX(calc(0px - #{$length})) rotateX(90deg);
                    transform-origin: 0 0;

                    &.open {
                        transform:
                            rotateY(90deg)
                            translateX(calc(0px - #{$length}))
                            rotateX(80deg);
                    }
                }

                &-4{
                    top: 1px;
                    width: $length;
                    height: calc(#{$width} / 3);
                    transform: translateX($width) rotateY(-90deg) rotateX(90deg);
                    transform-origin: 0 0;

                    &.open {
                        transform: translateX($width) rotateY(-90deg) rotateX(80deg);
                    }
                }
            }
        }

        &-3{
            #{$self}--side {
                height: $length;
                transform: rotateX(90deg);
                transform-origin: 0 0;
                transition-delay: 0.2s;

                &.open {
                    transition-delay: 0s;
                    transform:
                        translateZ(calc(0px - (#{$deep} / 2)))
                        translateY(calc(0px - (#{$deep} / 2)))
                        rotateX(155deg);
                }
            }
        }
    }
}
</style>