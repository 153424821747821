<template>
    <div class="m-container">
        <div class="m-ctrl">
            <div>
                <button @click="box.type = '1'">type 1</button>
                <button @click="box.type = '2'">type 2</button>
                <button @click="box.type = '3'">type 3</button>
                <button @click="openBox">Toggle box</button>
            </div>
            <div>
                <p>
                    Width: {{box.width}}
                </p>
                <input type="range" min="100" max="400" v-model="box.width"
                       @input="test1"
                       @change="test2"
                >
            </div>
            <div>
                <p>
                    Height: {{box.height}}
                </p>
                <input type="range" min="100" max="400" v-model="box.height">
            </div>
            <div>
                <p>
                    Length: {{box.length}}
                </p>
                <input type="range" min="100" max="400" v-model="box.length">
            </div>
            <div>
                <p>
                    Deep: {{box.deep}}
                </p>
                <input type="range" min="1" max="20" v-model="box.deep">
            </div>
            <div>
                <p>
                    Ox: {{ox}}
                </p>
                <input type="range" min="0" max="360" v-model="ox">
            </div>
            <div>
                <p>
                    Oy: {{oy}}
                </p>
                <input type="range" min="-100" max="720" v-model="oy">
            </div>
        </div>
        <div class="m-box--container"
             @mousedown="!drag3dStart"
             @mouseup="drag3dEnd"
             @mousemove="drag3dMove"
             @mouseleave="drag3dEnd"
             :style="styleObject"
        >
            <div class="m-box" ref="box"
                 :class="{'m-resize': isResize}"
            >
                <m-top
                    :isOpen="box.isOpen"
                    :type="box.type"
                    @m-select-side="selectSide"
                ></m-top>

                <m-side @m-select-side="selectSide" type="right"></m-side>
                <m-side @m-select-side="selectSide" type="left"></m-side>
                <m-side @m-select-side="selectSide" type="bottom"></m-side>
                <m-side @m-select-side="selectSide" type="front"></m-side>
                <m-side @m-select-side="selectSide" type="back"></m-side>
            </div>
        </div>
    </div>
</template>

<script>
import Side from './Side'
import Top from './Top'

export default {
    name: "Box",
    components:{
        'm-side': Side,
        'm-top': Top
    },
    props: ['configuration'],
    data() {
        return {
            isResize: false,
            isDrag3d: false,
            ox3d: 0,
            oy3d: 0,
            ox3dStart: 0,
            oy3dStart: 0,
            ox: 350,
            oy: 40,
            box: {
                width: 200,
                height: 150,
                length: 200,
                deep: 10,
                isOpen: true,
                type: '3'
            }
        }
    },
    computed: {
        styleObject(){
            return {
                '--box-width': this.box.width + 'px',
                '--box-height': this.box.height + 'px',
                '--box-length': this.box.length + 'px',
                '--box-ox': this.ox + 'deg',
                '--box-oy': this.oy + 'deg',
                '--box-deep': this.box.deep + 'px',
            }
        },
    },
    methods: {
        selectSide(type){
            let box = {}
            if(type === 'left' || type === 'right'){
                box.width = this.box.length
                box.height = this.box.height
            }
            if(type === 'front' || type === 'back'){
                box.width = this.box.width
                box.height = this.box.height
            }
            this.$emit('mSelectSide', {type: type, box: box})
        },
        test1(e) {
            this.isResize = true
        },
        test2(e) {
            this.isResize = false
        },
        drag3dStart(e) {
            let self = this
            self.isDrag3d = true
            self.ox3dStart = e.clientX
            self.oy3dStart = e.clientY
        },
        drag3dEnd() {
            let self = this
            self.isDrag3d = false
        },
        drag3dMove(e) {
            let self = this
            if (self.isDrag3d) {
                let smoothing = 4
                let oy = (self.oy3dStart - e.clientY) / smoothing
                let ox = (e.clientX - self.ox3dStart) / smoothing
                let container = self.$refs.box
                container.style.transform = `rotateY(${ox}deg) rotateX(${oy}deg)`
            }
        },
        openBox() {
            this.box.isOpen = !this.box.isOpen
        }
    }
}
</script>

<style lang="scss">
.m-container{
    display: grid;
    grid-template-columns: 450px 1fr;
    margin-bottom: 200px;
}

.m-ctrl{
    padding: 20px;

    div{
        padding: 10px 0;
    }

    p{
        margin-bottom: 5px;
    }

    input{
        width: 100%;
        cursor: pointer;
    }
}

.m-box--container {
    padding: 250px 50px 100px 50px;
}

.m-box {
    $width: var(--box-width);
    $height: var(--box-height);
    $ox: var(--box-ox);
    $oy: var(--box-oy);

    transform-origin: 50% 50%;
    margin: 0 auto;
    position: relative;
    width: $width;
    height: $height;
    transform-style: preserve-3d;
    perspective: 20000px;
    background-color: rgba(0, 0, 0, 0.1);
    transform: rotateY(#{$oy}) rotateX(#{$ox}) rotateZ(-8deg);

    &.m-resize{
        *{
            transition: 0s;
        }
    }

}
</style>