<template>
    <canvas id="canvas" width="300" height="300"></canvas>

    <button @click="add">addText</button>
    <button @click="addImage">addImg</button>
<!--    <button @click="toJSON">toJSON</button>-->
<!--    <button @click="loadFromJSON">load from JSON</button>-->
    <button @click="save">Save</button>

    <div v-for="item in objects">
        <button @click="remove(item)">remove</button>
    </div>
</template>

<script>
import {Configurator} from '@/configurator/configurator'
import store from "@/store";
export default {
    name: "Canvas",
    props: ['params'],
    data(){
        return {
            canvas: false,
            img: false,
        }
    },
    watch:{

    },
    mounted() {
        this.canvas = new Configurator('canvas', this.params)
        this.resize()
    },
    computed: {
        objects(){
            if(this.canvas){
                return this.canvas.getElements()
            }
        }
    },
    methods: {
        add() {
            this.canvas.addTextBox('test text', {
                fontSize: 20
            })
        },
        resize() {
            this.canvas.setCanvasSize()
        },
        addImage() {
            this.canvas.addImg()
        },
        toJSON() {
            this.canvas.toJSON()
        },
        loadFromJSON() {
            this.canvas.loadFromJson()
        },
        remove(el) {
            this.canvas.removeObj(el)
        },
        save() {
            let img = this.canvas.getImg()
            this.$emit('m-save', img)
        },
    }
}
</script>

<style >
.canvas-container{
    border: 1px solid black;
    margin: 20px auto;
    background-image: url("../../src/assets/images/outer.jpg");
    background-size: cover;
}
</style>