import { fabric } from 'fabric';

export class Configurator {
    constructor(el, params) {
        this.el = el;
        this.params = params;
        this.additional = ['id']
        this.maxHeight = 400
        this.init()
        console.log(params)
    }
    getElements(){
        return this.canvas.getObjects()
    }
    init() {
        this.canvas = new fabric.Canvas(this.el);
    }
    addSvg(){
        console.log(this.canvas)
    }
    addImg(){
        let self = this
        fabric.Image.fromURL('./assets/images/vue.png', function (img) {
            img.id = '1924ikaflked'
            self.canvas.add(img)
            self.changeCanvas()
        });
    }
    addTextBox(text, params){
        let textbox = new fabric.Textbox(text, params);
        textbox.id = 'v,mndfqw1142'
        this.canvas.add(textbox)
        this.changeCanvas()
    }
    changeCanvas(){
        this.render()
    }
    getImg(){
        let img = this.canvas.toDataURL({
            format: 'png',
            left: 0,
            top: 0,
        });

        return img
    }
    setCanvasSize() {
        let self = this
        self.canvas.wrapperEl.style.width = '100%'
        let width = self.canvas.wrapperEl.clientWidth
        let ratio = self.params.width / self.params.height
        let height = width / ratio

        if (height > self.maxHeight) {
            height = self.maxHeight
            width = height * ratio
        }
        self.canvas.setDimensions({width: width, height: height});
        self.canvas.wrapperEl.style.height = height + 'px'
    }
    toJSON(){
        let json = this.canvas.toJSON(this.additional)
        localStorage.setItem('state', JSON.stringify(json))
    }
    loadFromJson(){
        let json = localStorage.getItem('state')
        this.canvas.loadFromJSON(JSON.parse(json))
    }
    render(){
        this.canvas.requestRenderAll();
    }
    removeObj(obj){
        this.canvas.remove(obj)
        this.changeCanvas()
    }
}