<template>
    <div>
        <m-box @m-select-side="selectSide" :configuration="configuration"></m-box>
        <m-popup :show="showCanvasPopup" @close="showCanvasPopup = false">
            <v-canvas :params="params" @m-save="save"></v-canvas>
        </m-popup>
    </div>
</template>

<script>
import Canvas from "@/components/Canvas";
import Box from "@/components/Box/Box";
import Popup from "@/components/Popup/Popup";


export default {
    name: 'App',
    components: {
        'v-canvas': Canvas,
        'm-box': Box,
        'm-popup': Popup,
    },
    data(){
        return {
            showCanvasPopup: false,
            params: false,
            configuration: {}
        }
    },
    watch:{

    },
    mounted() {

    },
    computed: {

    },
    methods: {
        save(img){
            this.configuration[this.type] = {}
            this.configuration[this.type].img = img
            this.showCanvasPopup = false
        },
        selectSide(params){
            this.type = params.type
            this.params = params.box
            this.showCanvasPopup = true
        }
    }
}
</script>

<style lang="scss">

</style>
