<template>
    <transition name="fade">
        <div class="m-popup" v-if="show">
            <div class="m-popup--cover" @click.prevent="closePopup"></div>

            <div class="m-popup--body">
                <div class="m-popup--close" @click.prevent="closePopup">x</div>
                <slot></slot>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "Popup",
    props: [
        'show'
    ],
    data(){
        return {
            popup: {
                show: false,
                id: false,
                info: false,
            },
        }
    },
    methods: {
        closePopup() {
            this.$emit('close')
        },
    }
}
</script>

<style lang="scss" scoped>
.fade {
    &-enter-active,
    &-leave-active {
        transition: opacity 0.5s ease;
    }

    &-enter-from,
    &-leave-to {
        opacity: 0;
    }
}

.m-popup {
    width: 100%;
    min-width: 320px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    z-index: 3000;
    background-color: rgba(0,0,0,0.3);

    @supports (backdrop-filter: blur(2px)) {
        backdrop-filter: blur(2px);
        //background-color: transparent;
    }

    &--cover {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
    }

    &--body {
        position: relative;
        z-index: 10;
        margin: 50px auto;
        display: block;
        width: 100%;
        max-width: 820px;
        background-color: #ffffff;
        overflow: hidden;
        box-shadow: 0px 0px 15px -5px rgba(0,0,0,.5);

        @media (max-width: 768px) {
            max-width: 400px;
        }
    }

    &--close {
        $size: 30px;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        width: $size;
        min-width: $size;
        height: $size;
        padding: 0;
    }
}

</style>