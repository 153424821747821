<template>
    <div class="m-box--side" :class="'m-box--'+type"
    >
        <div class="m-box--side-space">
            <div @click="selectSide(type)" :style="{backgroundImage: 'url('+ getImg() +')'}"></div>
            <div v-for="n in 5"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Side",
    props: ['type'],
    methods: {
        selectSide(type){
            this.$emit('mSelectSide', type)
        },
        getImg(type){
            let img = this.$root.configuration[this.type]?this.$root.configuration[this.type].img:''
            return img
        }
    }
}
</script>

<style lang="scss">
.m-box {
    $transform: rotateY(180deg);
    $length: var(--box-length);
    $width: var(--box-width);
    $height: var(--box-height);
    $deep: var(--box-deep);

    &--side {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: inherit;
        border: 1px solid rgba(255, 255, 255, 1);
        background-image: url("../../../src/assets/images/outer.jpg");
        background-size: cover;
        transform-style: preserve-3d;
        transition: 0.3s;
        cursor: pointer;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: red;
            opacity: 0;
            pointer-events: none;
        }

        &:hover {
            &:after {
                opacity: 0.5;
            }
        }

        &-space{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform-style: preserve-3d;
            background-image: inherit;

            div {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: inherit;
                background-size: inherit;

                &:nth-of-type(1) {
                    padding: 20px;
                    position: relative;
                    background-size: 100%;
                    background-repeat: no-repeat;
                }

                &:nth-of-type(2) {
                    transform: translate3d(0, 0, calc(0px - #{$deep}));
                    background-image: url("../../../src/assets/images/inner.jpg");
                    background-size: cover;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        box-sizing: border-box;
                        border: 1px solid rgb(240 236 236);
                        //border-top-width: 1px;
                    }
                }

                &:nth-of-type(3) {
                    width: $deep;
                    transform: rotateY(90deg);
                    transform-origin: 0 100%;
                }

                &:nth-of-type(4) {
                    width: $deep;
                    transform: rotateY(-90deg);
                    transform-origin: 100% 0;
                    left: auto;
                    right: 0;
                }

                &:nth-of-type(5) {
                    height: $deep;
                    transform: rotateX(-90deg);
                    transform-origin: 0 0;
                }

                &:nth-of-type(6) {
                    height: $deep;
                    transform: rotateX(90deg);
                    transform-origin: 0 100%;
                    top: auto;
                    bottom: 0;
                }
            }
        }

        &[class*=back] {
            padding: 20px;
            position: relative;
            transform: rotateX(180deg);
        }

        &[class*=front] {
            transform: translate3d(0, 0, $length);
        }

        &[class*=left] {
            width: $length;
            transform: rotateY(-90deg);
            transform-origin: 0 100%;
        }

        &[class*=right] {
            width: $length;
            transform: rotateY(90deg);
            transform-origin: 100% 0;
            left: auto;
            right: 0;
        }

        &[class*=bottom] {
            height: $length;
            transform: rotateX(-90deg);
            transform-origin: 0 100%;
            top: auto;
            bottom: 0;
        }
    }
}
</style>